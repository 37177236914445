import React from 'react';
import { SliderButton } from '@typeform/embed-react';
import useWindowDimensions from 'hooks/useWindowDimensions';

interface ITypeFormComponent {
	children?: React.ReactNode;
}

const TypeFormComponent: React.FC<ITypeFormComponent> = ({ children }) => {
	const screen = useWindowDimensions();
	const width = screen?.width || 340;

	return (
		<SliderButton
			id='JGohptmy'
			// id='k0sNSMDP'
			medium='snippet'
			width={width > 1000 ? 1000 : width * 0.9}
			hideHeaders={true}
			iframeProps={{ title: 'Clinic Registration (from BP website)' }}
			style={{ background: 'transparent', maxWidth: '95vw' }}
			position='right'
		>
			{children}
		</SliderButton>
	);
};

export default TypeFormComponent;
