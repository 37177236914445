import { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce/lib';

function debounce(fn, delay) {
	let timer = null;
	return function () {
		const context = this,
			args = arguments;
		clearTimeout(timer);
		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
}

const getWindowDimensions = () => {
	if (typeof window !== `undefined`) {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	}
};

export default function useWindowDimensions() {
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		const debouncedHandleResize = debounce(function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}, 1000);

		window.addEventListener('resize', debouncedHandleResize);
		return () => window.removeEventListener('resize', debouncedHandleResize);
	}, []);

	return windowDimensions;
}
