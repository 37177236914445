import React from 'react';
import { ThemeProvider } from 'styled-components';

export const theme = {
	palette: {
		main: '#000000',
		bg: '#f7f8f9',
		midGrey: '#E5E5E5',
		purp: '#424BEC',
		white: '#FFFFFF',
		bg_grey: 'rgba(0, 0, 0, 0.15)',
		inactive: '#00000026',
		inactiveTab: '#00000066',
		inactiveTabLine: '#F0F0F1',
		grey_text: '#7C7C7C',
		bg_purp: '#424bec1a',
		bg_footer: '#081B2D',
		shadow: '#0000001c',
		fail: '#FF5B5B',
		green: '#1FA163',
		light: '#424becc9',
		modal_bg: '#00000066',
		modal_bg_text: '#748095',
		items_border: '#BDC5CD',
	},
};

const ThemeProviderComponent: React.FC<{ children: React.ReactNode }> = ({ children }) => (
	<ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default ThemeProviderComponent;
